.navbar ul {
    list-style-type: none;
    overflow: hidden;
    margin: 0px;
    padding: 20px;
}

.navbar li {
    display: inline;
}

.navbar li {
    float: left;
}

.navbar li a {
    display: block;
    padding: 0px;
    text-decoration: none;
    text-align: center;
    color: #050310;
    margin-left: 50px;
    font-size: 22px;
    font-family: "Gill Sans";
}

.navbar li h2 {

    margin: 0px;
    font-size: 22px;
}

.container {
    display: grid;
    grid-template-columns: 10rem 10rem 10rem;
    grid-gap: 1rem;
}

.grid-container {
    display: grid;
    grid-template-columns: auto auto;
    padding: 10px; 
}

.grid-item {
    border: rgba(0, 0, 0, 0);
    padding: 10px;
    font-size: 30px;
    text-align: center;
}

.grid-item-2 {
    border: rgba(0, 0, 0, 0);
    padding: 10px;
    font-size: 30px;
    text-align: center;
    margin-top: 30px;
}

p.one {
    margin-left: 50px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 50px;
    line-height: 1.1;
    font-family: "Gill Sans";
}

p.two {
    margin-left: 50px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 50px;
    line-height: 1.1;
    font-family: "Gill Sans";
}

a {
    text-decoration: underline;
    color: #050310;
}

.betasignup p.one {
    margin-left: 70px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 50px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 30px;  
}

.betasignup p.two {
    margin-left: 70px;
    margin-top: 20px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 450px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 30px;  
}

.betasignup ul {
    list-style-type: none;
    overflow: hidden;
    margin: 0px;
    padding: 20px;
}

.betasignup li {
    display: inline;
    float: left;
}

.betasignup li a {
    display: block;
    padding: 0px;
    text-decoration: none;
    text-align: center;
    color: #050310;
    margin-left: 50px;
    font-size: 22px;
    font-family: "Gill Sans";
}

.betasignup li h2 {

    margin: 0px;
    font-size: 22px;
}

.betasignup label {
    margin-left: 75px;
    font-size: 32px;
    font-family: "Gill Sans";
}

.contactform ul {
    list-style-type: none;
    overflow: hidden;
    margin: 0px;
    padding: 20px;
}

.contactform li {
    display: inline;
}

.contactform li {
    float: left;
}

.contactform li a {
    display: block;
    padding: 0px;
    text-decoration: none;
    text-align: center;
    color: #050310;
    margin-left: 50px;
    font-size: 22px;
    font-family: "Gill Sans";
}

.contactform li h2 {

    margin: 0px;
    font-size: 22px;
}

.contactform a {
    text-decoration: underline;
    color: #050310;
}

.contactform p.one {
    margin-left: 450px;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 40px;   
}

.contactform p.two {
    margin-left: 350px;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 20px;   
}

.contactform p.three {
    margin-top: 40px;
}

.contactform label.one {
    margin-left: 350px;
    margin-top: 100x;
    font-size: 25px;
    margin-right:0px;
}

.contactform input[type=text]{
    padding: 15px;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: -200px;
    margin-bottom: 0px;
    margin-left: 350px;
    resize: vertical;
    border-width: 2px;
    width: 400px;
}

.contactform input[type=submit] {
    background-color: black;
    color: white;
    padding: 30px 40px;
    border: none;
    border-radius: none;
    margin-left: 470px;
    font-size: 20px;
}

.contactform textarea {
    margin-left: 350px;
    resize: vertical;
    margin-top: 0px;
    width:400px;
    border-width: 2px;
}

.about p.three {
    margin-left: 70px;
    margin-top: 20px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 450px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 30px;  
}

.betasignup p.one {
    margin-left: 70px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 50px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 30px;  
}

.betasignup p.two {
    margin-left: 70px;
    margin-top: 20px;
    text-align: justify;
    letter-spacing: 0px;
    margin-right: 450px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 30px;  
}

.betasignup label {
    margin-left: 75px;
    font-size: 32px;
    font-family: "Gill Sans";
}

.contactform p.one {
    margin-left: 450px;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 40px;   
}

.contactform p.two {
    margin-left: 350px;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 20px;
}

.contactform input[type=text]{
    padding: 15px;
    border: 1px solid;
    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-left: 350px;
    resize: vertical;
    border-width: 2px;
    width: 400px;
}

.contactform input[type=submit] {
    background-color: black;
    color: white;
    padding: 30px 40px;
    border: none;
    border-radius: none;
    margin-left: 470px;
    font-size: 20px;
}

.contactform textarea {
    margin-left: 350px;
    resize: vertical;
    margin-top: 0px;
    width:400px;
    height: 200px;
    border-width: 2px;
}

.contactform label_1 {
    margin-left: 350px;
    margin-bottom: 100px;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 25px;   
}

.contactform label_2 {
    margin-left: 350px;
    margin-bottom: 100px;
    text-align: justify;
    letter-spacing: 0px;
    line-height: 1.1;
    font-family: "Gill Sans";
    font-size: 25px;   
}

.contactform form {
    margin-bottom: 20px;
}

